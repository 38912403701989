import {APP_INITIALIZER, importProvidersFrom, Inject, Injectable, NgModule} from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { TaasinatorRoutingModule } from './taasinator-routing.module';
import { environment } from '../../environments/environment';
import { Alert } from '../shared/util/alert';
import { COMMON_LIB_CONFIG_TOKEN, ICommonLibConfig } from '../shared/common-lib-config';
import { appHttpInterceptor } from '../shared/services/http/app-http-interceptor.service';
import { AppConfigService } from './services/app-config.service';
import { SharedModule } from '../shared/shared.module';
import {APP_CONFIG_SERVICE_TOKEN, IAppConfigService} from '../shared/services/auth/auth.service';
import { TenantService } from '../shared/services/tenant/tenant.service';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class PageTitleStrategy extends TitleStrategy {
  constructor(private title: Title, private tenant: TenantService, @Inject(APP_CONFIG_SERVICE_TOKEN) private appConfig: IAppConfigService) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    let title = 'Conductor.ai';
    if (this.appConfig.userProfile) {
      title += ` - ${this.appConfig.userProfile.tenant.name}`;
    }
    const routeTitle = this.buildTitle(routerState);
    if (routeTitle) {
      title += ` - ${routeTitle}`;
    }
    this.title.setTitle(title);
  }
}
@NgModule({
  providers: [
    importProvidersFrom(SharedModule, TaasinatorRoutingModule),
    provideHttpClient(withInterceptors([appHttpInterceptor])),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId, { oneTapEnabled: false })
          }
        ],
        onError: (error) => {
          Alert.message({
            severity: 'error',
            summary: 'Google Auth',
            detail: error.details.toString(),
            life: 10000
          });
        }
      } satisfies SocialAuthServiceConfig
    },
    {
      provide: COMMON_LIB_CONFIG_TOKEN,
      useValue: {
        apiUrl: environment.apiUrl,
        imageServiceUrl: '/api/images/upload',
        fileStorageUrl: '/api/file-storage/upload',
        auth: {
          googleAuthUrl: '/api/auth/google',
          passwordAuthUrl: '/api/auth/password',
          refreshTokenUrl: '/api/auth/refresh-token'
        }
      } satisfies ICommonLibConfig
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: AppConfigService): () => Promise<any> => {
        return () => service.init();
      },
      deps: [AppConfigService, TenantService],
      multi: true
    },
    { provide: APP_CONFIG_SERVICE_TOKEN, useExisting: AppConfigService },
    {provide: TitleStrategy, useClass: PageTitleStrategy}
  ]
})
export class TaasinatorModule {
}
