import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http';
import { IUserMePayload, IUserRecordWithPermissions } from '../../api/shared/auth-user-api';
import { ICountry, ITenant } from '../../api/shared/common';
import { OnDemandLoader } from '../../shared/services/resources/on-demand-resource-loader.service';
import { IGNORE_MESSAGES } from '../../shared/services/http/app-http-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class CommonResourceService {
  constructor(private http: HttpClient) {
  }

  getCurrentUserProfile(ignoreUnauthorized?: boolean): Observable<IUserMePayload> {
    return this.http.get<IUserMePayload>('/api/users/me',
      ignoreUnauthorized ? { context: new HttpContext().set(IGNORE_MESSAGES, [401]) } : undefined);
  }

  @OnDemandLoader('countries')
  getCountries(): Observable<Array<ICountry>> {
    return this.http.get<Array<ICountry>>('/api/common/countries');
  }

}
