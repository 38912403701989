import {Directive, inject, OnInit, ViewContainerRef} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {Toast} from 'primeng/toast';
import {ConfirmPopup} from 'primeng/confirmpopup';
import {ConfirmDialog} from 'primeng/confirmdialog';
import {ConfirmExitUnsavedComponent} from './confirm';

@Directive()
export class AbstractRootComponent implements OnInit {
  protected primengConfig = inject(PrimeNGConfig);
  protected viewContainerRef = inject(ViewContainerRef);

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.viewContainerRef.createComponent(Toast);
    const confirmPopup = this.viewContainerRef.createComponent(ConfirmPopup);
    confirmPopup.setInput('key', 'app-confirm-popup');
    confirmPopup.setInput('defaultFocus', 'reject');
    const confirmDialog = this.viewContainerRef.createComponent(ConfirmDialog);
    confirmDialog.setInput('key', 'app-confirm-dialog');
    confirmDialog.setInput('style', {width: '30vw'});
    confirmDialog.setInput('acceptButtonStyleClass', 'p-button-text');
    confirmDialog.setInput('defaultFocus', 'g-reject');
    this.viewContainerRef.createComponent(ConfirmExitUnsavedComponent);
  }
}

