import { Component, Inject, ViewChild } from '@angular/core';
import { APP_CONFIG_SERVICE_TOKEN, AuthService, IAppConfigService } from '../../services/auth/auth.service';
import { IEmailPasswordPayload } from '../../../api/shared/auth-user-api';
import { FormsModule, NgModel } from '@angular/forms';
import { RippleModule } from 'primeng/ripple';
import { NullableDirective } from '../nullable.directive';
import { NgClass, NgIf } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { SpinnerizerDirective } from '../spinnerizer.component';

@Component({
  selector: 'app-login-page',
  template: `
    <div class="flex justify-content-center align-items-center" (keydown.enter)="passwordSignIn()">
      <div [spinnerizer]="auth.isSigningIn" class="mt-3">
        @if (auth.isSignedIn) {
          <p-messages severity="info">
            <ng-template pTemplate>
              <div class="flex align-items-center justify-content-between" style="min-width: 600px">
            <span class="flex align-items-center">
              <i class="pi pi-info-circle mr-3 text-2xl"></i>
              <span>Your signed in as</span><span class="ml-2 font-medium">{{ appConfig.userProfile?.name }}</span>
            </span>
                <p-button label="Sign Out" icon="pi pi-sign-out" styleClass="ml-2 p-button-sm"
                          (click)="auth.signOut()"></p-button>
              </div>
            </ng-template>
          </p-messages>
        } @else {
          <div class="w-full p-card p-6">
            <div class="text-center w-full mb-3">
              <img alt="google" src="assets/images/logo.svg" width="40"/>
            </div>
            <div class="flex align-items-center justify-content-center">
              <!-- https://stackoverflow.com/questions/69242615/prevent-sign-in-with-google-button-flickering-while-loading-when-centered-vertic -->
              <div style="height: 44px; width: auto">
                <asl-google-signin-button type="standard" size="medium" shape="square" [width]="350"
                                          theme="filled_blue">
                </asl-google-signin-button>
              </div>
            </div>
            <p-divider layout="horizontal" align="center">
              OR
            </p-divider>
            <div class="p-fluid">
              <div class="field">
                <label>Email address</label>
                <input #loginModel="ngModel" type="email" name="email" [email]="true" pInputText
                       [(ngModel)]="credentials.email" required
                       [ngClass]="(loginModel.control.dirty || triedLogin ) && loginModel.invalid ? 'ng-invalid ng-dirty' : ''">
                <div *ngIf="(loginModel.control.dirty || triedLogin ) && loginModel.invalid"
                     class="p-error text-xs">
                  {{ !credentials.email ? 'Email is required' : 'Wrong email' }}
                </div>
              </div>
              <div class="field">
                <label>Password</label>
                <input #passwordModel="ngModel" name="password" type="password" pInputText required
                       [(ngModel)]="credentials.password" nullable
                       [ngClass]="(passwordModel.control.dirty || triedLogin ) && passwordModel.invalid ? 'ng-invalid ng-dirty' : ''">
                <div *ngIf="(passwordModel.control.dirty || triedLogin ) && passwordModel.invalid"
                     class="p-error text-xs">
                  Password is required
                </div>
              </div>
              <button class="mt-3" type="submit" pRipple pButton
                      [disabled]="!canPasswordSignIn()"
                      (click)="passwordSignIn()">
              <span class="flex align-items-center w-full justify-content-center">
                <i class="pi pi-user"></i>
                <span class="ml-2">Login</span>
              </span>
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  `,
  standalone: true,
  imports: [
    MessagesModule,
    SharedModule,
    ButtonModule,
    GoogleSigninButtonModule,
    DividerModule,
    FormsModule,
    InputTextModule,
    NgClass,
    NgIf,
    NullableDirective,
    RippleModule,
    SpinnerizerDirective
  ]
})
export class LoginPageComponent {
  @ViewChild('passwordModel') passwordModel!: NgModel;
  @ViewChild('loginModel') loginModel!: NgModel;

  triedLogin = false;
  credentials: Omit<IEmailPasswordPayload, 'tenant'> = {
    email: '',
    password: ''
  };

  constructor(public auth: AuthService,
              @Inject(APP_CONFIG_SERVICE_TOKEN) public appConfig: IAppConfigService) {
  }

  canPasswordSignIn(): boolean {
    return !this.auth.isSigningIn && (this.passwordModel?.valid ?? false) && (this.loginModel?.valid || false);
  }

  passwordSignIn(): void {
    if (this.canPasswordSignIn()) {
      this.triedLogin = true;
      if (this.credentials.password && this.credentials.email) {
        this.auth.passwordSignIn(this.credentials);
      }
    }
  }

}
