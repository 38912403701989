import {ChangeDetectorRef, Directive, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {find, isEmpty} from 'lodash';
import {AbstractEntityTable} from './abstract-entity-table';

export interface IFilterByQueryParamDef {
  field: string;
  matchMode: string;  // see FilterMatchMode;
  isValueArray?: boolean;
}


@Directive({
    selector: '[filterByQueryParams]',
    standalone: true,
})
export class FilterByQueryParamsDirective implements OnInit {
  @Input('filterByQueryParams') defs!: Array<IFilterByQueryParamDef>;

  constructor(public entityTable: AbstractEntityTable<any>,
              private route: ActivatedRoute,
              private router: Router,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      const inQueryParams: Array<{ qp: IFilterByQueryParamDef; value: any; }> = [];
      const clearQueryParams: Params = {};
      for (const p of params.keys) {
        const qp = find(this.defs,
          (qp) => 'colFilter_' + qp.field === p);
        if (!!qp && !!params.get(p)) {
          inQueryParams.push({qp, value: params.get(p)});
          clearQueryParams[p] = null;
        }
      }
      for (const qp of this.defs) {
        const found = find(inQueryParams, (f) => f.qp.field === qp.field);
        this.entityTable.filters[qp.field] = [
          {
            matchMode: qp.matchMode,
            value: !!found ? (qp.isValueArray ? [found.value] : found.value) : null,
            operator: 'and'
          }
        ];
      }
      if (!isEmpty(clearQueryParams)) {
        this.cdr.detectChanges();
        this.entityTable.load();
        this.router.navigate([], {relativeTo: this.route, queryParams: clearQueryParams, queryParamsHandling: 'merge', replaceUrl: true});
      }

    }).unsubscribe();
  }
}


