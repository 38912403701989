import {Component, inject} from '@angular/core';
import {SkillEditorComponent} from './skill-editor.component';
import {EntityEditPageComponent} from '../../../../../shared/components/entity-editor/entity-edit-page.component';
import {SharedModule} from 'primeng/api';
import {SkillsResourceService} from '../../../../resources/dictionaries/skills-resource.service';
import {AbstractEditPageWrapper} from '../../../../../shared/components/entity-editor/abstract-edit-page-wrapper';

@Component({
  selector: 'app-skill-edit-page',
  template: `
    <app-entity-edit-page [api]="api">
      <ng-template pTemplate="editor" let-param>
        <app-skill-editor [param]="param"></app-skill-editor>
      </ng-template>
    </app-entity-edit-page>
  `,
  standalone: true,
  imports: [
    EntityEditPageComponent,
    SharedModule,
    SkillEditorComponent
  ],
})
export class SkillEditPageComponent extends AbstractEditPageWrapper {
  api = inject(SkillsResourceService);
}
