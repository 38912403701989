import {Directive, Input} from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Observable} from 'rxjs';

import {existsAsyncValidator} from '../util/validators';
import {forwardProvider} from '../util/util';

@Directive({
  selector: '[existsAsync][ngModel]',
  providers: [forwardProvider(NG_ASYNC_VALIDATORS, ExistsAsyncValidatorDirective)],
  standalone: true
})
export class ExistsAsyncValidatorDirective implements AsyncValidator {
  @Input('existsAsync') checkFn!: (value: any) => Observable<boolean>;
  @Input() delay = 300;

  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return existsAsyncValidator(this.checkFn, this.delay)(control);
  }
}


@Directive({
  selector: '[exists][ngModel]',
  providers: [
    forwardProvider(NG_VALIDATORS, ExistsValidatorDirective)
  ],
  standalone: true
})
export class ExistsValidatorDirective implements Validator {
  @Input('exists') checkFn!: (value: any) => boolean;

  validate(
    control: AbstractControl
  ): ValidationErrors | null {
    return this.checkFn(control.value) ? {exists: true} : null;
  }
}
