import {inject} from '@angular/core';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptorFn,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import {from, mergeMap, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {TokenSupplierService} from '../auth/token-supplier.service';
import {HttpDataConverterService} from './http-data-converter.service';
import {cloneDeep} from 'lodash';
import {COMMON_LIB_CONFIG_TOKEN, ICommonLibConfig} from '../../common-lib-config';
import {Alert} from '../../util/alert';

export const IGNORE_MESSAGES= new HttpContextToken<Array<number>>(() => []);
export const appHttpInterceptor: HttpInterceptorFn = (request, next) => {
  const auth = inject(AuthService);
  const dataConverter = inject(HttpDataConverterService);
  const config = inject<ICommonLibConfig>(COMMON_LIB_CONFIG_TOKEN);

  if (request.body && !(request.body instanceof FormData)) {
    request = request.clone({
      body: dataConverter.out(cloneDeep(request.body))
    });
  }

  return from(inject(TokenSupplierService).getOrRefreshToken())
    .pipe(
      catchError((error) => {
        auth.signOut();
        return throwError(error);
      }),
      map((token) => {
        let headers = request.headers;
        const params = new HttpParams({fromString: request.params.toString()});
        if (!!token) {
          headers = headers.set('Authorization', `Bearer ${token}`);
        }
        return request.clone({
          url: `${config.apiUrl}${request.url}`,
          params,
          headers
        });
      }),
      mergeMap((request) => {
        return next(request).pipe(
          tap((ev: HttpEvent<any>) => {
            if (ev instanceof HttpResponse) {
              dataConverter.in(ev.body);
            }
          }),
          catchError((response) => {
            if (response instanceof HttpErrorResponse) {
              const httpErrorResponse = response as HttpErrorResponse;
              if (httpErrorResponse.status === 401) {
                auth.signOut();
              }
              const ignoreMessages = request.context.get(IGNORE_MESSAGES);
              if (!ignoreMessages?.includes(httpErrorResponse.status)) {
                const detail = httpErrorResponse.error?.message || httpErrorResponse.message;
                Alert.message({
                  severity: 'error',
                  summary: 'Response Error',
                  detail,
                  life: 10000
                });
              }
            }
            return throwError(response);
          })
        );
      })
    );
};

