import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { CommonResourceService } from '../resources/common-resource.service';
import { IUserMePayload } from '../../api/shared/auth-user-api';
import { IAppConfigService } from '../../shared/services/auth/auth.service';
import { TenantService } from '../../shared/services/tenant/tenant.service';
import { ITenant } from '../../api/shared/common';

const NO_TENANT_PAGE = `
<div style="margin-top: 8rem; display: flex; justify-content: center; align-items: center">
  <div>
      <div style="background-color: #FFF; width: 100%; box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
           border-radius: 4px; padding: 2rem; text-align: center">
           <h1 style="color: #7f6003">\u{026A0}</h1>
           <h2 style="color: #7f6003">Organisation not defined</h2>
           <p>The application could not be loaded.</p>
           <p>The correct application URL format is <b>\`{ORIGIN}/your-organization-id\`</b></p>
      </div>
  </div>
</div>
`;

@Injectable({
  providedIn: 'root'
})
export class AppConfigService implements IAppConfigService {
  constructor(private commonResource: CommonResourceService,
              private tenant: TenantService) {
  }

  private _userProfile: IUserMePayload | null = null;

  get userProfile(): IUserMePayload | null {
    return this._userProfile;
  }

  get tenants(): Array<ITenant> {
    return this.userProfile?.tenants || [];
  }

  init(onSignIn?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!onSignIn && !this.tenant.hasTenant()) {
        document.body.innerHTML = NO_TENANT_PAGE.replace('{ORIGIN}', location.origin);
        reject('Organization not defined');
      } else {
        forkJoin([
          this.commonResource.getCurrentUserProfile(true)
        ])
          .pipe(
            catchError((e) => {
              if (e.status === 401) {
                resolve();
              } else {
                if (!onSignIn) {
                  alert(`The application initialization failed: ${ e.error?.message ?? e.message }`);
                }
                reject();
              }
              throw e;
            })
          )
          .subscribe(([profile]) => {
            this._userProfile = profile;
            this.setFavIcon(profile.tenant.avatar);
            resolve();
          });
      }
    });
  }

  clear() {
    this._userProfile = null;
    this.setFavIcon(null);
  }

  private setFavIcon(icon: string | null | undefined): void {
    const favIcon: HTMLLinkElement | null = document.querySelector('#favIcon');
    if (favIcon) {
      favIcon.href = icon || 'assets/favicon.png';
    }
  }
}
