import {IsNumber, IsString} from 'class-validator';
import {Type} from 'class-transformer';
import {TransformValidation} from '../../util/transform-validation';
import {decodeJwtPayload} from '../../util/util';

export interface IJwtToken {
  iat: number;
  exp?: number;
}

export interface IAuthUser extends IJwtToken {
  email: string;
}

export class AuthUser implements IAuthUser {
  @IsString()
  @Type(() => String)
  email!: string;
  @IsNumber()
  @Type(() => Number)
  iat!: number;
  static create(token: string): IAuthUser | null {
    try {
      const user = TransformValidation.performSync<AuthUser>(AuthUser, decodeJwtPayload(token), {notThrowError: true});
      return user;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

}
