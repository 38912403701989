import {inject, Injector, NgModule} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Alert} from './util/alert';

@NgModule({
  providers: [
    MessageService,
    ConfirmationService,
  ],
})
export class SharedModule {
  constructor() {
    Alert.injector = inject(Injector);
  }
}
