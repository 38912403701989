import {validate, validateOrReject, validateSync, ValidatorOptions} from 'class-validator';
import {ClassConstructor, ClassTransformOptions, plainToClass} from 'class-transformer';

import {Alert} from './alert';

export interface ITransformValidationOptions {
  validator?: ValidatorOptions;
  transformer?: ClassTransformOptions;
  notThrowError?: boolean;
}

export class TransformValidation {

  static perform<T extends object>(
    classType: ClassConstructor<T>,
    toTransform: string | object | object[],
    options?: ITransformValidationOptions,
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      let object = TransformValidation.getObjectToTransform(toTransform, reject);
      const classObject = plainToClass(classType, object, options?.transformer);
      if (Array.isArray(classObject)) {
        Promise.all(
          classObject.map((objectElement) =>
            validate(objectElement, options?.validator),
          ),
        ).then((errors) =>
          errors.every((error) => error.length === 0) ? resolve(classObject) : reject(errors)
        );
      } else {
        validateOrReject(classObject, options?.validator)
          .then(() => resolve(classObject))
          .catch(reject);
      }
    });
  }

  static performSync<T extends object>(
    classType: ClassConstructor<T>,
    toTransform: string | object | object[],
    options?: ITransformValidationOptions,
  ): T | null {
    let object = TransformValidation.getObjectToTransform(toTransform);
    const classObject = plainToClass(classType, object, options?.transformer);


    const errorsArray = (Array.isArray(classObject) ? classObject : [classObject]).map((objectElement) =>
      validateSync(objectElement, options?.validator),
    );
    if (errorsArray.some((errors) => errors.length !== 0)) {
      Alert.message({
        severity: 'error',
        summary: 'Data Validation Error',
        detail: errorsArray.toString(),
        life: 10000
      });
      if (options?.notThrowError) {
        return null;
      } else {
        throw errorsArray;
      }
    }
    return classObject;
  }

  private static getObjectToTransform(
    toTransform: string | object | object[], reject?: (err?: any) => void
  ): object | null {
    const err = `TransformValidation::perform - Incorrect object param type: ${typeof toTransform}`;
    if (typeof toTransform === 'string') {
      return JSON.parse(toTransform);
    } else if (!!toTransform && typeof toTransform === 'object') {
      return toTransform;
    }
    if (!!reject) {
      reject(
        new Error(err)
      );
    } else {
      throw new Error(err);
    }
    return null;
  }
}
