import {Injectable} from '@angular/core';
import {Endpoint, EntityResourceBaseService} from '../../../shared/services/resources/entity-resource-base.service';
import {IPaidTimeOff, TPaidTimeOffSave} from '../../../api/shared/app-domain/dictionaries';
import {Observable} from 'rxjs';
import {OnDemandLoader} from '../../../shared/services/resources/on-demand-resource-loader.service';
import {IIdentified, INamed} from '../../../api/shared/common';


@Injectable({
  providedIn: 'root',
})
@Endpoint('/api/settings/paid-time-offs')
export class PaidTimeOffsResourceService extends EntityResourceBaseService<TPaidTimeOffSave, IPaidTimeOff> {

  @OnDemandLoader('paidTimeOffs')
  paidTimeOffs(): Observable<Array<INamed & IIdentified>> {
    return this.distinctFetchHelper({sort: [{field: 'name'}], fields: ['id', 'name']});
  }

}
