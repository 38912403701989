import {Component} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {AbstractEntityEditor} from '../../../../../shared/components/entity-editor/abstract-entity-editor';
import {inheritanceProvider, plural} from '../../../../../shared/util/util';
import {AbstractEntityEditorBase} from '../../../../../shared/components/entity-editor/abstract-entity-editor-base';
import {kebabCase} from 'lodash';
import {SkillsResourceService} from '../../../../resources/dictionaries/skills-resource.service';
import {ISkill, TSkillSave} from '../../../../../api/shared/app-domain/dictionaries';
import {TControlsOf} from '../../../../../shared/types';
import {existsAsyncValidator} from '../../../../../shared/util/validators';
import {ButtonModule} from 'primeng/button';
import {SkillsFormControlComponent} from './skills-form-control.component';
import {NgIf} from '@angular/common';
import {SpinnerizerDirective} from '../../../../../shared/components/spinnerizer.component';

const AUTO_FILLS: Record<any, string> = {
  'Design': 'Designer',
  'Development': 'Developer',
  'Marketing': 'Marketer',
  'Production': 'Producer',
  'Strategy': 'Expert',
  'Technology': 'Engineer'
};

const UNFILLED = '\u{0270D}';

@Component({
  selector: 'app-skill-editor',
  template: `
    <div [spinnerizer]="loading">
      <form *ngIf="form" #frm [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-4 p-fluid">
            <app-skills-form-control field="name"></app-skills-form-control>
          </div>
          <div class="field col-3 p-fluid">
            <app-skills-form-control field="silo"></app-skills-form-control>
          </div>
          <div class="field col-1 p-fluid" [style.padding-top]="'1.8rem'">
            <p-button label="Auto Fill" [disabled]="!canAutoFill()" (click)="autoFill()"></p-button>
          </div>
          <div class="field col-4 p-fluid">
            <app-skills-form-control field="urlSlug"></app-skills-form-control>
          </div>

          <div class="field col-4 p-fluid">
            <app-skills-form-control field="adjective"></app-skills-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-skills-form-control field="singular"></app-skills-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-skills-form-control field="plural"></app-skills-form-control>
          </div>

          <div class="field col-6 p-fluid">
            <app-skills-form-control field="team"></app-skills-form-control>
          </div>
          <div class="field col-6 p-fluid">
            <app-skills-form-control field="teams"></app-skills-form-control>
          </div>

          <div class="field col-6 p-fluid">
            <app-skills-form-control field="siloMember"></app-skills-form-control>
          </div>
          <div class="field col-6 p-fluid">
            <app-skills-form-control field="siloMembers"></app-skills-form-control>
          </div>

          <ng-container *ngIf="!isNew()">
            <div class="field col-3 p-fluid">
              <app-skills-form-control field="isArchived"></app-skills-form-control>
            </div>
            <div class="field col-2 p-fluid pt-5 flex justify-content-center">
              <app-skills-form-control field="isForReview"></app-skills-form-control>
            </div>
          </ng-container>
          <div class="field col-2 p-fluid pt-5">
            <app-skills-form-control field="isOkToPublish"></app-skills-form-control>
          </div>


          <div class="field col-12 p-fluid">
            <app-skills-form-control field="metaTitle"></app-skills-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-skills-form-control field="metaDescriptions"></app-skills-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    inheritanceProvider(AbstractEntityEditorBase, SkillEditorComponent),
  ],
  standalone: true,
  imports: [
    NgIf, FormsModule, ReactiveFormsModule, SkillsFormControlComponent, ButtonModule,
    SpinnerizerDirective
  ]
})
export class SkillEditorComponent extends AbstractEntityEditor<TSkillSave, ISkill> {

  constructor(public skillsResource: SkillsResourceService) {
    super(skillsResource, 'Skill');
  }

  override buildForm(): void {
    const unfilledValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      if (control.value != null && control.value.includes(UNFILLED)) {
        return {'Value is not completed': true};
      }
      return null;
    };
    const checkExistsName = this.getCheckExists(this.skillsResource.searchEntities.bind(this.skillsResource), 'name');
    this.form = this.fb.group<TControlsOf<TSkillSave>>({
      name: new FormControl(this.entity!.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExistsName)]
      }),
      isArchived: new FormControl(this.isNew() ? false : this.entity!.isArchived, [Validators.required]),
      isForReview: new FormControl(this.isNew() ? false : this.entity!.isForReview, [Validators.required]),
      silo: new FormControl(this.entity!.silo, [Validators.required]),
      adjective: new FormControl(this.entity!.adjective, [Validators.required, unfilledValidator]),

      singular: new FormControl(this.entity!.singular, [Validators.required, unfilledValidator]),
      plural: new FormControl(this.entity!.plural, [Validators.required, unfilledValidator]),
      team: new FormControl(this.entity!.team, [Validators.required]),
      teams: new FormControl(this.entity!.teams, [Validators.required]),
      siloMember: new FormControl(this.entity!.siloMember, [Validators.required, unfilledValidator]),
      siloMembers: new FormControl(this.entity!.siloMembers, [Validators.required, unfilledValidator]),
      urlSlug: new FormControl(this.entity!.urlSlug, [Validators.required, unfilledValidator]),
      metaTitle: new FormControl(this.entity!.metaTitle, []),
      metaDescriptions: new FormControl(this.entity!.metaDescriptions, []),
      isOkToPublish: new FormControl(this.isNew() ? true : this.entity!.isOkToPublish, [Validators.required]),
    });
  }


  canAutoFill(): boolean {
    return this.getValue('name') != null && this.getValue('silo') != null && this.getValue('silo') !== 'TBD';
  }

  autoFill(): void {
    if (this.canAutoFill()) {
      let name: string = this.getValue('name');
      const nameParts = name.split(' ');
      const silo = this.getValue('silo');
      const autoFillToken = AUTO_FILLS[silo] || UNFILLED;
      const autoFillTokenPlural = plural(autoFillToken);
      if ([autoFillToken.toLowerCase(), silo.toLowerCase()].includes(nameParts[nameParts.length - 1].toLowerCase())) {
        nameParts.pop();
        name = nameParts.join(' ');
      }
      this.setValueAndMark('adjective', `Fluid team of ${autoFillTokenPlural.toLowerCase()}`);
      this.setValueAndMark('singular', `${name} ${autoFillToken}`);
      this.setValueAndMark('plural', `${name} ${autoFillTokenPlural}`);
      this.setValueAndMark('siloMember', autoFillToken);
      this.setValueAndMark('siloMembers', autoFillTokenPlural);
      this.setValueAndMark('urlSlug', kebabCase(`${name} ${autoFillTokenPlural}`));
      this.setValueAndMark('team', `${name} ${silo} Team`);
      this.setValueAndMark('teams', `${name} ${silo} Teams`);
    }
  }
}
