import {Component, inject} from '@angular/core';
import {HolidayCalendarEditorComponent} from './holiday-calendar-editor.component';
import {SharedModule} from 'primeng/api';
import {EntityEditPageComponent} from '../../../../../shared/components/entity-editor/entity-edit-page.component';
import {HolidayCalendarsResourceService} from '../../../../resources/dictionaries/holiday-calendars-resource.service';
import {AbstractEditPageWrapper} from '../../../../../shared/components/entity-editor/abstract-edit-page-wrapper';

@Component({
  selector: 'app-holiday-calendar-edit-page',
  template: `
    <app-entity-edit-page [api]="api">
      <ng-template pTemplate="subTitle" let-param let-entity="entity">
        <div class="text-gray-700 flex align-items-center">
          <span class="mr-2">Country of origin: {{ entity.countryName }}</span>
          <img class="mr-2" [src]="entity.countryFlag" width="24">
          <span>Based on: {{ entity.baseName || 'itself' }}</span>
        </div>
      </ng-template>
      <ng-template pTemplate="editor" let-param>
        <app-holiday-calendar-editor [param]="param"></app-holiday-calendar-editor>
      </ng-template>
    </app-entity-edit-page>
  `,
  standalone: true,
  imports: [
    EntityEditPageComponent,
    SharedModule,
    HolidayCalendarEditorComponent,
  ],
})
export class HolidayCalendarEditPageComponent extends AbstractEditPageWrapper {
  api = inject(HolidayCalendarsResourceService);
}
