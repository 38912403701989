import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RouterNavigate {
  private router = inject(Router);
  private location = inject(Location);

  back(): void {
    this.location.back();

    // if (document.referrer) {
    //   this.location.back();
    // } else {
    //   this.router.navigate(['']);
    // }
  }
}
