import { Injectable, OnDestroy } from '@angular/core';
import { TenantService } from '../tenant/tenant.service';
import { getStorageItem, murmurHash, setStorageItem } from '../../util/util';

interface ITokenPair {
  accessToken?: string;
  refreshToken?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService implements OnDestroy {
  private readonly storageListener?: (ev: StorageEvent) => void;
  private readonly tokenKey: string | null = null;

  constructor(private tenant: TenantService) {
    if (tenant.hasTenant()) {
      this.tokenKey = `#t_${murmurHash(this.tenant.tenantId!).toString(16)}`;
      this.storageListener = (ev: StorageEvent) => {
        if (ev.key === this.tokenKey) {
          location.reload();
        }
      };
      window.addEventListener('storage', this.storageListener);
    }
  }

  ngOnDestroy(): void {
    if (this.storageListener) {
      window.removeEventListener('storage', this.storageListener);
    }
  }

  private readTokenPair(): ITokenPair {
    if (!this.tenant.hasTenant()) {
      return {};
    }
    return getStorageItem<ITokenPair>(this.tokenKey!) ?? {};
  }

  private writeTokenPair(tokenPair: ITokenPair): void {
    if (!this.tenant.hasTenant()) {
      return;
    }
    setStorageItem(this.tokenKey!, { ...this.readTokenPair(), ...tokenPair });
  }

  private clear(token: keyof ITokenPair | 'both'): void {
    if (!this.tenant.hasTenant()) {
      return;
    }
    const pair = getStorageItem<ITokenPair>(this.tokenKey!);
    if (!pair) {
      return;
    }
    if (token === 'both') {
      localStorage.removeItem(this.tokenKey!);
    } else {
      delete pair[token];
      setStorageItem(this.tokenKey!, pair);
    }
  }

  getAccessToken(): string | null {
    return this.readTokenPair().accessToken || null;
  }

  setAccessToken(accessToken: string): void {
    this.writeTokenPair({ accessToken });
  }

  clearBadAccessToken(): void {
    this.clear('accessToken');
  }

  getRefreshToken(): string | null {
    return this.readTokenPair().refreshToken || null;
  }

  setRefreshToken(refreshToken: string): void {
    this.writeTokenPair({ refreshToken });
  }

  clearBothTokens(): void {
    this.clear('both');
  }
}
