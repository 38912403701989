import {Component} from '@angular/core';
import {TSkillSave} from '../../../../../api/shared/app-domain/dictionaries';
import {
  AbstractFieldFormControl,
  FormControlWrapperComponent
} from '../../../../../shared/components/entity-editor/form-control-wrapper.component';
import {inheritanceProvider} from '../../../../../shared/util/util';
import {OptionsPipe} from '../../../../../shared/components/options.pipe';
import {AsyncPipe} from '@angular/common';
import {CommonFormControlIsArchived} from '../../../common/common-controls-wrappers';


@Component({
  selector: 'app-skills-form-control',
  template: `
    @switch (field) {
      @case ('isArchived') {
        <app-fc-isArchived></app-fc-isArchived>
      }
      @case ('silo') {
        <app-form-control-wrapper controlType="dropdown" [dropdownEditable]="true"
                                  onDemandOptions="skillSilos"></app-form-control-wrapper>
      }
      @case ('isForReview') {
        <app-form-control-wrapper label="For Review" dataType="boolean"></app-form-control-wrapper>
      }
      @case ('urlSlug') {
        <app-form-control-wrapper label="URL Slug"></app-form-control-wrapper>
      }
      @case ('metaDescriptions') {
        <app-form-control-wrapper dataType="text"></app-form-control-wrapper>
      }
      @case ('isOkToPublish') {
        <app-form-control-wrapper label="Ok to Publish" dataType="boolean"></app-form-control-wrapper>
      }
      @default {
        <app-form-control-wrapper></app-form-control-wrapper>
      }
    }
  `,
  providers: [
    inheritanceProvider(AbstractFieldFormControl, SkillsFormControlComponent)
  ],
  standalone: true,
  imports: [
    CommonFormControlIsArchived,
    FormControlWrapperComponent,
    AsyncPipe,
    OptionsPipe,
  ],
})
export class SkillsFormControlComponent extends AbstractFieldFormControl<TSkillSave> {
}

