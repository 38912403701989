import {Inject, Injectable, InjectionToken} from '@angular/core';
import {BypassHttpService} from '../http/bypass-http.service';
import {
  IAuthPayload,
  IAuthResponse,
  IEmailPasswordPayload, IUserMePayload,
  IUserRecordWithPermissions
} from '../../../api/shared/auth-user-api';
import {catchError, finalize, Observable, ReplaySubject} from 'rxjs';
import {Router} from '@angular/router';
import {SocialAuthService} from '@abacritt/angularx-social-login';
import {COMMON_LIB_CONFIG_TOKEN, ICommonLibConfig} from '../../common-lib-config';
import {AuthUser, IAuthUser} from './auth-user';
import { TenantService } from '../tenant/tenant.service';
import {TokenStorageService} from './token-storage.service';
import { ITenant } from '../../../api/shared/common';


export interface IAppConfigService {
  init(onSignIn?: boolean): Promise<void>;
  clear(): void;
  readonly userProfile: IUserMePayload | null;
}
export const APP_CONFIG_SERVICE_TOKEN = new InjectionToken<IAppConfigService>('CommonLibConfig')
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user: IAuthUser | null = null;
  private _isSigningIn: boolean = false;
  private _authState: ReplaySubject<IAuthUser | null> = new ReplaySubject(1);

  constructor(
    private socialAuthService: SocialAuthService,
    private bypassHttp: BypassHttpService,
    private router: Router,
    private tenant: TenantService,
    private tokenStorage: TokenStorageService,
    @Inject(COMMON_LIB_CONFIG_TOKEN) private commonConfig: ICommonLibConfig,
    @Inject(APP_CONFIG_SERVICE_TOKEN) private appConfig: IAppConfigService
  ) {
    const token = tokenStorage.getAccessToken();
    this.setUser(token);

    this.subscribeToGoogleState();
  }

  private subscribeToGoogleState(): void {
    this.socialAuthService.authState.subscribe((socialUser) => {
      this._isSigningIn = true;
      this.bypassHttp.request<IAuthResponse, IAuthPayload>('post', this.commonConfig.auth.googleAuthUrl, {
        body: {
          token: socialUser.idToken,
          tenant: this.tenant.tenantId!
        }
      }).pipe(
        catchError((error) => {
          throw error;
        }),
        finalize(() => this._isSigningIn = false)
      ).subscribe(async (response) => {
        this.setUser(response.token);
        this.tokenStorage.setRefreshToken(response.refreshToken);
        try {
          await this.appConfig.init(true);
          this.router.navigate([''], {replaceUrl: true});
        } catch (e) {
          this.setUser(null);
        } finally {
          this._isSigningIn = false;
        }
      });
    });
  }

  passwordSignIn(credentials: Omit<IEmailPasswordPayload, 'tenant'>): void {
    this._isSigningIn = true;
    this.bypassHttp
      .request<IAuthResponse, IEmailPasswordPayload>('post', this.commonConfig.auth.passwordAuthUrl, {
        body: {...credentials, ...{tenant: this.tenant.tenantId!}}
      })
      .pipe(
        catchError((error) => {
          throw error;
        }),
        finalize(() => this._isSigningIn = false)
      )
      .subscribe(async (authResponse) => {
        this.setUser(authResponse.token);
        this.tokenStorage.setRefreshToken(authResponse.refreshToken)
        try {
          await this.appConfig.init(true);
          this.router.navigate([''], {replaceUrl: true});
        } catch (e) {
          this.setUser(null);
        } finally {
          this._isSigningIn = false;
        }
      });
  }

  setUser(accessToken: string | null): void {
    if (!!accessToken) {
      this._user = AuthUser.create(accessToken);
      this._authState.next(this._user);
      if (!!this._user) {
        this.tokenStorage.setAccessToken(accessToken);
      } else {
        this.tokenStorage.clearBadAccessToken();
      }
    } else {
      this._user = null;
      this._authState.next(null);
      this.tokenStorage.clearBothTokens();
    }
  }

  signOut(): void {
    if (this.isSignedIn) {
      this.setUser(null);
      this.appConfig.clear();
      this.router.navigate(['login']);
    }
  }

  get isSignedIn(): boolean {
    return !!this._user;
  }

  get isSigningIn(): boolean {
    return this._isSigningIn;
  }

  get authState(): Observable<IAuthUser | null> {
    return this._authState.asObservable();
  }

  get user(): IAuthUser | null {
    return this._user;
  }
}
