import {Injectable} from '@angular/core';
import {Endpoint, EntityResourceBaseService} from '../../../shared/services/resources/entity-resource-base.service';
import {IWorkweek, TWorkweekSave} from '../../../api/shared/app-domain/dictionaries';
import {Observable} from 'rxjs';
import {OnDemandLoader} from '../../../shared/services/resources/on-demand-resource-loader.service';
import {IIdentified, INamed} from '../../../api/shared/common';
import {tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
@Endpoint('/api/settings/workweeks')
export class WorkweeksResourceService extends EntityResourceBaseService<TWorkweekSave, IWorkweek> {

  @OnDemandLoader('workweeks')
  workweeks(): Observable<Array<INamed & IIdentified>> {
    return this.distinctFetchHelper({sort: [{field: 'name'}], fields: ['id', 'name']});
  }

  replaceWorkweeks(workweeks: Array<TWorkweekSave>): Observable<Array<IWorkweek>> {
    return this.http.post<Array<IWorkweek>>(this.url('replace'), workweeks)
      .pipe(tap((r) => this.onEntityModified('patch', r)));
  }

}
