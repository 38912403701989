import {Injectable} from '@angular/core';
import {dateToISOString, tryConvertToDate} from '../../util/util';

@Injectable({
  providedIn: 'root'
})
export class HttpDataConverterService {

  static convertOutputDate(date: Date): string {
    return dateToISOString(date);
  }

  static traverseInput(data: any): any {
    if (typeof data !== 'object') {
      return data;
    }

    for (const key in data) {
      if (!data.hasOwnProperty(key)) {
        continue;
      }
      const value = data[key];
      if (typeof value === 'string') {
        const date = tryConvertToDate(value);
        if (date) {
          data[key] = date;
        }
      } else if (typeof value === 'object') {
        HttpDataConverterService.traverseInput(value);
      }
    }
  }

  static traverseOutput(data: any): any {
    if (typeof data !== 'object') {
      return data;
    }

    for (const key in data) {
      if (!data.hasOwnProperty(key)) {
        continue;
      }
      const value = data[key];
      if (value instanceof Date) {
        data[key] = HttpDataConverterService.convertOutputDate(value);
      } else if (typeof value === 'object') {
        HttpDataConverterService.traverseOutput(value);
      }
    }
  }

  in(data: any): any {
    if (data) {
      HttpDataConverterService.traverseInput(data);
    }
    return data;
  }

  out(data: any): any {
    if (data) {
      HttpDataConverterService.traverseOutput(data);
    }
    return data;
  }

}

