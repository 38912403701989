import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanDeactivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AuthService} from './auth/auth.service';
import {IDataModifier} from '../components/common';
import {confirmExitUnsaved} from '../components/confirm';

export const loginGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
  const auth = inject(AuthService);
  return auth.authState.pipe(
    map((user) => {
      if (!!user) {
        return new UrlTree();
      }
      return true;
    })
  );
}

export const authGuard: CanActivateFn = (): Observable<boolean> => {
  const router = inject(Router);
  const auth = inject(AuthService);
  return auth.authState.pipe(
    map((user) => !!user),
    tap((isLoggedIn) => {
      if (!isLoggedIn) {
        router.navigate(['login']);
      }
    })
  );
}

export const pendingChangesGuard: CanDeactivateFn<IDataModifier> = (component: IDataModifier) => {
  if (component.hasUnsavedChanges()) {
    return confirmExitUnsaved();
  }
  return true;
}
