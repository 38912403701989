import {Component, ViewChild} from '@angular/core';
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel';
import {MenuModule} from 'primeng/menu';
import {ListboxChangeEvent, ListboxModule} from 'primeng/listbox';
import {NgIf} from '@angular/common';
import {MenuItem, SharedModule} from 'primeng/api';
import {AuthService} from '../../../../shared/services/auth/auth.service';
import {TenantService} from '../../../../shared/services/tenant/tenant.service';
import {AppConfigService} from '../../../services/app-config.service';
import {USER_MENU} from '../main-menu-models';
import {ITenant} from '../../../../api/shared/common';
import {find} from 'lodash';
import {MenuItemCommandEvent} from 'primeng/api/menuitem';

@Component({
  selector: 'app-user-nav',
  template: `
    <div class="flex align-items-center cursor-pointer h-full" (click)="opUser.toggle($event)">
      <div class="mt-app-top-bar-avatar">
        <i *ngIf="!appConfig.userProfile?.avatar; else avatar" class="pi pi-user"></i>
        <ng-template #avatar>
          <img [src]="'data:image/jpeg;base64,' + appConfig.userProfile?.avatar"
               style="border-radius: 50%" width="30">
        </ng-template>
      </div>
      <i class="ml-1 pi pi-angle-down"></i>
    </div>
    <p-overlayPanel #opUser styleClass="mt-user-popup" (onShow)="onShowUserMenu()">
      <ng-template pTemplate>
        <div class="border-bottom-1 border-gray-300 px-3 py-2">
          <div>Hi {{ appConfig.userProfile?.name }}</div>
          <div class="mt-1 text-xs text-gray-500">{{ auth.user?.email }}</div>
        </div>
        <p-menu [model]="userMenuItems"></p-menu>
      </ng-template>
    </p-overlayPanel>
    <p-overlayPanel #opTenants>
      <p-listbox [options]="getOtherTenants()" [listStyle]="{ maxHeight: '250px'}" (onChange)="switchWorkspace($event)">
        <ng-template let-tenant pTemplate="item">
          <div class="flex align-items-center gap-2" style="min-width: 200px">
            <img [src]="tenant.avatar" style="width: 24px; height: 24px;"/>
            <div>{{ tenant.name }}</div>
          </div>
        </ng-template>
      </p-listbox>
    </p-overlayPanel>
  `,
  imports: [
    OverlayPanelModule,
    MenuModule,
    ListboxModule,
    NgIf,
    SharedModule
  ],
  standalone: true,
  styles: [
    `
      ::ng-deep .mt-user-popup.p-overlaypanel .p-overlaypanel-content {
        padding: 0;
      }

      ::ng-deep .mt-user-popup.p-overlaypanel .p-overlaypanel-content .p-menu {
        border: none;
        font-size: .8rem;
      }
    `
  ]
})
export class UserNavComponent {
  userMenuItems: MenuItem[];
  @ViewChild('opUser') userMenuPanel!: OverlayPanel;
  @ViewChild('opTenants') tenantsPanel!: OverlayPanel;


  constructor(public auth: AuthService,
              public tenant: TenantService,
              public appConfig: AppConfigService) {
    this.userMenuItems = USER_MENU.map((item) => {
      return {
        ...item,
        ...{
          command: () => {
            switch (item.id) {
              case 'sign-out':
                this.auth.signOut();
                break;
              default:
                this.userMenuPanel.hide();
            }
          },
        }
      };
    });
  }

  getOtherTenants(): Array<ITenant> {
    return this.appConfig.tenants.filter((t) => t.id !== this.tenant.tenantId);
  }

  canSwitchTenant(): boolean {
    return !!this.getOtherTenants().length;
  }

  onShowUserMenu(): void {
    find(this.userMenuItems, {id: 'switch-workspace-sep'})!.visible = this.canSwitchTenant();
    const item = find(this.userMenuItems, {id: 'switch-workspace'})!;
    item.visible = this.canSwitchTenant();
    item.command = (event: MenuItemCommandEvent) => this.tenantsPanel.show(event.originalEvent);
  }

  switchWorkspace(ev: ListboxChangeEvent): void {
    this.tenantsPanel.hide();
    if (ev) {
      location.href = location.origin + '/' + ev.value.id;
    }
  }
}
