import {Injectable} from '@angular/core';
import {Endpoint, EntityResourceBaseService} from '../../../shared/services/resources/entity-resource-base.service';
import {IHolidayCalendar, IHolidaysByDate, THolidayCalendarSave} from '../../../api/shared/app-domain/holidays';
import {Observable} from 'rxjs';
import {OnDemandLoader} from '../../../shared/services/resources/on-demand-resource-loader.service';

@Injectable({
  providedIn: 'root',
})
@Endpoint('/api/holiday-calendars')
export class HolidayCalendarsResourceService extends EntityResourceBaseService<THolidayCalendarSave, IHolidayCalendar> {
  getHolidaysUniverse(year: number): Observable<Array<IHolidaysByDate>> {
    return this.http.get<Array<IHolidaysByDate>>(this.url('holidays-universe/:year', {year}));
  }

  @OnDemandLoader('holidayCalendars')
  holidayCalendars(): Observable<Array<Pick<IHolidayCalendar, 'id' | 'name' | 'countryFlag'>>> {
    return this.distinctFetchHelper({sort: [{field: 'name'}], fields: ['id', 'name', 'countryFlag']});
  }
}
