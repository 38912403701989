import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private readonly _tenantId: string | null = null;

  get tenantId(): string | null {
    return this._tenantId;
  }

  constructor() {
    if ((window as any).appSettings) {
      this._tenantId = (window as any).appSettings.tenantId as string || null;
    }
  }

  hasTenant(): boolean {
    return !!this.tenantId;
  }
}
