import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormsModule, NgModel, ValidationErrors} from '@angular/forms';
import {Calendar, CalendarModule} from 'primeng/calendar';
import {Observable, of} from 'rxjs';
import {find, isDate, isEmpty, maxBy, minBy, omit, range, remove, sortBy} from 'lodash';
import {AsyncPipe, DatePipe, NgClass, NgIf} from '@angular/common';
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel';
import {tap} from 'rxjs/operators';
import {AbstractControlValueAccessor} from '../../../../../shared/components/abstract-control-value-accessor';
import {IHoliday, IHolidayCalendar, THolidayCalendarSave} from '../../../../../api/shared/app-domain/holidays';
import {IPeriod} from '../../../../../api/shared/common';
import {getCurrentPeriodValue, PeriodChooserComponent} from '../../../../../shared/components/period-chooser.component';
import {valueAccessorProvider} from '../../../../../shared/util/util';
import {HolidayCalendarsResourceService} from '../../../../resources/dictionaries/holiday-calendars-resource.service';
import {
  OnDemandResourceLoaderService
} from '../../../../../shared/services/resources/on-demand-resource-loader.service';
import {ConstantsProviderService} from '../../../../resources/constants-provider.service';
import {DEFAULT_CALENDAR_YEAR_RANGE} from './calendar';
import {TooltipModule} from 'primeng/tooltip';
import {RippleModule} from 'primeng/ripple';
import {ListboxModule} from 'primeng/listbox';
import {ButtonModule} from 'primeng/button';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ControlErrorComponent} from '../../../../../shared/components/control-error.component';
import {ExistsAsyncValidatorDirective} from '../../../../../shared/components/exists-validator.directive';
import {NullableDirective} from '../../../../../shared/components/nullable.directive';
import {LifeCycleHookDirective} from '../../../../../shared/components/life-cycle-hook.directive';
import {InputTextModule} from 'primeng/inputtext';
import {SharedModule} from 'primeng/api';
import {DropdownModule} from 'primeng/dropdown';

interface IDateMeta {
  day: number;
  month: number;
  year: number;
}

@Component({
  selector: 'app-holiday-calendar',
  template: `
    <div #container [style.min-height]="'500px'">
      <ng-container #container *ngIf="data" lifecycle (onInitialized)="onInitialized()">
        <div class="formgrid grid p-fluid">
          <div *ngIf="showBasicCalendarChooser" class="field col-3">
            <label>Basic Calendar</label>
            <p-dropdown #model="ngModel" [(ngModel)]="basicCalendarId"
                        [disabled]="disabled"
                        placeholder="Select basic calendar"
                        [autoDisplayFirst]="false"
                        [filter]="true"
                        optionLabel="name" optionValue="id" (ngModelChange)="onChooseBasicCalendar($event)"
                        [options]="$any(onDemandLoader.observe('holidayCalendars') | async)">
              <ng-template let-item pTemplate="selectedItem">
                <div class="flex align-items-center" *ngIf="model.value != null">
                  <img [src]="item.countryFlag" width="14" class="mr-2"/>
                  <div>{{ item.name }}</div>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="flex align-items-center">
                  <img [src]="item.countryFlag" width="24" class="mr-2"/>
                  <div>{{ item.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <ng-container *ngIf="isStandalone">
            <div class="field" [ngClass]="showBasicCalendarChooser ? 'col-3' : 'col-4'">
              <label class="mt-required-field">Calendar Name</label>
              <input #nameModel="ngModel" [(ngModel)]="data.name" pInputText
                     required [class.ng-dirty]="true"
                     lifecycle (onInit)="nameModel.control.markAsDirty()"
                     [existsAsync]="checkExists"
                     (ngModelChange)="applyValue()" nullable/>
              <app-control-error [control]="nameModel.control"></app-control-error>
            </div>
            <div class="field col-6">
              <label class="mt-required-field">Description</label>
              <textarea #descriptionModel="ngModel" [(ngModel)]="data.description"
                        pInputTextarea [autoResize]="true" [rows]="1"
                        required [class.ng-dirty]="true"
                        [style.min-height]="'34px'" (ngModelChange)="applyValue()"
                        lifecycle (onInit)="descriptionModel.control.markAsDirty()"
                        nullable></textarea>
              <app-control-error [control]="descriptionModel.control"></app-control-error>
            </div>
            <div *ngIf="!showBasicCalendarChooser" class="field col-2">
              <label>Archive Status</label>
              <p-dropdown [(ngModel)]="data.isArchived" [options]="constantsProvider.archivableStatus"
                          [autoDisplayFirst]="false"
                          placeholder="Archive Status"
                          (ngModelChange)="applyValue()">
              </p-dropdown>
            </div>
          </ng-container>
        </div>
        <p-overlayPanel #editorPanel [dismissable]="true" [showCloseIcon]="true" appendTo="body">
          <ng-template pTemplate>
            <div class="text-lg mb-3">Create new holiday on {{ createHolidayData.day | date:'MM/dd/yy' }}</div>
            <div style="width: 500px" class="formgrid">
              <div class="field p-fluid grid">
                <label class="col-3">
                  Holiday Name
                </label>
                <div class="col-9">
                  <input #model="ngModel" pInputText [(ngModel)]="createHolidayData.formData.date"
                         required [class.ng-dirty]="true"
                         lifecycle (onInit)="model.control.markAsDirty()"
                         (ngModelChange)="getHolidayByName($event) ? model.control.setErrors({exists: true}) : null"
                         nullable>
                  <app-control-error [control]="model.control"></app-control-error>
                </div>
              </div>
            </div>
            <div class="mt-3 flex align-items-center justify-content-end">
              <p-button icon="pi pi-check" [disabled]="!!model.invalid"
                        (onClick)="editorPanel.hide(); createHolidayData.onApply!(model.value)" label="Apply"
                        styleClass="p-button-text p-button"></p-button>
              <p-button icon="pi  pi-times" (onClick)="editorPanel.hide()" label="Cancel"
                        styleClass="p-button-text p-button"></p-button>
            </div>
          </ng-template>
        </p-overlayPanel>
        <div class="grid">
          <div class="col-3">
            <div class="mb-3 p-fluid">
              <app-period-chooser [(ngModel)]="selectedPeriod" [unitFilter]="['year']" [showUnits]="false"
                                  [disabled]="disabled"
                                  (ngModelChange)="onChangePeriod()"
                                  [minDate]="dateRange.start" [maxDate]="dateRange.end"></app-period-chooser>
            </div>
            <p-listbox class="holiday-list"
                       [disabled]="disabled"
                       scrollHeight="auto"
                       [(ngModel)]="selectedDates"
                       [options]="currentYearHolidays"
                       optionLabel="name" optionValue="observedDate"
                       filterPlaceHolder="Search for holiday"
                       [checkbox]="true" [filter]="true"
                       [multiple]="true" [metaKeySelection]="false"
                       (ngModelChange)="onHolidayListChange()">
              <ng-template let-holidayDate pTemplate="item">
                <div class="inline-flex align-items-center justify-content-between w-full">
                  <span [class.text-custom-holiday]="!!holidayDate.isCustom">{{ holidayDate.name }}</span>
                  <span class="flex align-items-center">
                      <span class="ml-2 text-basic-holiday text-right w-10rem"
                            [class.text-custom-holiday]="!!holidayDate.isCustom">
                        <span *ngIf="!!isMovedHoliday(holidayDate.observedDate, true)" class="">
                          <span class="line-through">{{ holidayDate.date | date: 'MM/dd/yy' }}</span>
                          <i class="ml-1 mr-1 pi pi-arrow-right"></i>
                        </span>
                        <span>{{ holidayDate.observedDate | date: 'MM/dd/yy' }}</span>
                      </span>
                      <button pButton pRipple icon="pi pi-trash"
                              [style.visibility]="!holidayDate.isCustom ? 'hidden' : 'auto'"
                              style="padding: 0.1rem; height: 2rem;  width: 2rem;"
                              class="p-button-rounded p-button-text p-button-sm"
                              (click)="removeDay($event, holidayDate)"></button>
                    </span>
                </div>
              </ng-template>
            </p-listbox>
          </div>
          <div #calendarContainer class="col-9" style="height: auto;">
            <p-calendar #holidayCalendar class="calendar"
                        [disabled]="disabled"
                        [(ngModel)]="selectedDates"
                        [showOtherMonths]="false"
                        [numberOfMonths]="12"
                        [firstDayOfWeek]="1"
                        [inline]="true"
                        selectionMode="multiple">
              <ng-template pTemplate="date" let-dateMeta>
                  <span #dayEl
                        [class.basic-holiday]="!!getHolidayByDate(dateMeta)"
                        [class.moved-holiday]="!!isMovedHoliday(dateMeta, false)"
                        [class.custom-holiday]="!!getHolidayByDate(dateMeta)?.isCustom"
                        (click)="onClickDay(dateMeta, $event, editorPanel, dayEl)"
                        [pTooltip]="getTooltip(dateMeta)!" tooltipPosition="bottom">
                    {{ dateMeta.day }}
                  </span>
              </ng-template>
            </p-calendar>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data == null">
        <div class="text-lg text-gray-600">There is no Holiday Calendar</div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      $calendar-height: 675px;
      $calendar-min-width: 800px;

      .text-basic-holiday {
        color: var(--gray-600);
      }

      .text-custom-holiday {
        font-weight: bold;
        color: var(--cyan-600);
      }

      ::ng-deep .p-overlaypanel-close {
        z-index: 1;
      }

      :host ::ng-deep .holiday-list .p-listbox {
        display: flex;
        flex-direction: column;
        height: calc($calendar-height - 45px);
      }

      :host ::ng-deep .holiday-list .p-ink {
        display: none !important;
      }

      :host ::ng-deep .calendar .p-calendar {
        min-height: $calendar-height;
        overflow-x: auto;
      }

      :host ::ng-deep .calendar .p-datepicker table {
        font-size: .9rem !important;
      }

      :host ::ng-deep .calendar .p-datepicker .p-datepicker-header .p-datepicker-prev,
      :host ::ng-deep .calendar .p-datepicker .p-datepicker-header .p-datepicker-next {
        display: none !important;
      }

      :host ::ng-deep .calendar .p-datepicker table td,
      :host ::ng-deep .calendar .p-datepicker table th {
        padding: .2rem !important;
      }

      :host ::ng-deep .calendar .p-datepicker table td > span {
        width: 1.8rem;
        height: 1.8rem;
        border: none;
      }

      :host ::ng-deep .calendar .p-datepicker {
        // border: none !important;
        min-width: $calendar-min-width;
      }

      :host ::ng-deep .calendar .p-datepicker .p-datepicker-group-container {
        flex-wrap: wrap;
        margin-right: -0.5rem;
        margin-left: -0.5rem;
        margin-top: -0.5rem;
        padding: 0 .5rem 0 .5rem;
        user-select: none;
      }

      :host ::ng-deep .calendar .p-datepicker .p-datepicker-group-container .p-datepicker-group {
        flex: 0 0 auto !important;
        padding: 0.5rem 1rem 0.5rem 1rem !important;
        width: 25% !important;
        border: none !important;
      }

      :host ::ng-deep .calendar .p-datepicker table td.p-datepicker-today > span {
        box-shadow: none;
        border: none;
      }

      :host ::ng-deep .calendar .p-datepicker table td.p-datepicker-today > span > span {
        border-color: #0000cd;
      }

      :host ::ng-deep .calendar .p-datepicker table td > span > .p-ink {
        display: none !important;
      }

      :host ::ng-deep .calendar .p-datepicker table td > span > span {
        position: absolute;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border: 1px solid transparent;
        z-index: 1;
      }

      :host ::ng-deep .calendar .p-datepicker table td > span.p-highlight > span.basic-holiday {
        background: var(--gray-700);
        color: #FFF;
      }

      :host ::ng-deep .calendar .p-datepicker table td > span.p-highlight > span.basic-holiday:hover {
        background: var(--gray-800) !important;
      }

      :host ::ng-deep .calendar .p-datepicker table td > span:not(.p-highlight) > span.basic-holiday {
        border-color: var(--gray-700);
      }

      :host ::ng-deep .calendar .p-datepicker table td > span > span.moved-holiday {
        text-decoration: line-through !important;
      }

      :host ::ng-deep .calendar .p-datepicker table td > span.p-highlight > span.custom-holiday {
        background: var(--cyan-700);
        color: #FFF;
      }

      :host ::ng-deep .calendar .p-datepicker table td > span.p-highlight > span.custom-holiday:hover {
        background: var(--cyan-800) !important;
      }

      :host ::ng-deep .calendar .p-datepicker table td > span:not(.p-highlight) > span.custom-holiday {
        border-color: var(--cyan-700);
      }


      :host ::ng-deep .calendar .p-datepicker table td > span.p-highlight {
        background: var(--gray-300);
      }

      :host ::ng-deep .calendar .p-datepicker:not(.p-disabled) table td > span.p-highlight:not(.p-disabled):hover {
        background: var(--gray-400) !important;
      }
    `
  ],
  providers: [
    valueAccessorProvider(HolidayCalendarControl),
  ],
  standalone: true,
  imports: [
    NgIf, DropdownModule, FormsModule, SharedModule, NgClass, InputTextModule, LifeCycleHookDirective,
    NullableDirective, ExistsAsyncValidatorDirective, ControlErrorComponent, InputTextareaModule, OverlayPanelModule,
    ButtonModule, PeriodChooserComponent, ListboxModule, RippleModule, CalendarModule, TooltipModule, AsyncPipe,
    DatePipe
  ]
})
export class HolidayCalendarControl extends AbstractControlValueAccessor<THolidayCalendarSave> implements OnInit {
  @Input() showBasicCalendarChooser = false;
  @Input() isStandalone = false;
  @Input() isDuplicate = false;
  @Input() checkExists: (value: any) => Observable<boolean> = (value: any) => of(false);

  @ViewChild('holidayCalendar', {read: Calendar}) set calendarComponent(c: Calendar) {
    if (c) {
      this._calendarComponent = c;
      this.calendarComponent.updateUI = () => {
        this.calendarComponent.currentMonth = 0;
        this.calendarComponent.currentYear = this.currentYear;
        this.calendarComponent.createMonths(this.calendarComponent.currentMonth, this.calendarComponent.currentYear);
      }
    }
  };

  @ViewChild('nameModel') set nameModel(m: NgModel) {
    if (m) {
      this.nameCtrl = m.control;
    }
  }

  nameCtrl?: FormControl;
  _calendarComponent!: Calendar;
  get calendarComponent(): Calendar {
    return this._calendarComponent;
  }

  data?: THolidayCalendarSave | null;
  yearRange: { start: number; end: number } = this.getDefaultYearRange();
  dateRange: { start: Date; end: Date } = this.updateDateRange();
  selectedPeriod: IPeriod = getCurrentPeriodValue('year');
  selectedDates: Array<Date> = [];
  currentYear = this.selectedPeriod.start.getFullYear();
  currentYearHolidays: Array<IHoliday> = [];
  holidaysChanged = false;
  basicCalendarId?: string;
  initialized = false;

  createHolidayData: {
    day?: Date,
    formData: Partial<IHoliday>;
    onApply?: (name: string) => void;
  } = {
    formData: {}
  };

  constructor(public holidayCalendarsResource: HolidayCalendarsResourceService,
              public onDemandLoader: OnDemandResourceLoaderService,
              public constantsProvider: ConstantsProviderService) {
    super();
  }

  get disabled(): boolean {
    return this.isDisabled;
  }

  getDefaultYearRange() {
    return {
      start: new Date().getFullYear() - DEFAULT_CALENDAR_YEAR_RANGE.backward,
      end: new Date().getFullYear() + DEFAULT_CALENDAR_YEAR_RANGE.forward
    }
  }

  onInitialized(): void {
    setTimeout(() => this.onValidatorChange());
  }

  ngOnInit(): void {
    if (this.isStandalone) {
      const chk = this.checkExists;
      this.checkExists = (val: any) => {
        return chk(val).pipe(tap((exists) => {
          if (exists) {
            setTimeout(() => this.onValidatorChange());
          }
        }));
      }
    }
  }

  updateDateRange() {
    if (!this.dateRange) {
      this.dateRange = {} as any;
    }
    this.dateRange.start = new Date(this.yearRange.start, 0, 1);
    this.dateRange.end = new Date(this.yearRange.end, 0, 1);
    return this.dateRange;
  }

  onChooseBasicCalendar(id: string): void {
    this.holidayCalendarsResource.getEntity(id)
      .pipe()
      .subscribe((response) => {
        this.setData(response, true);
        this.applyValue();
      });
  }

  getHolidayByDate(dateOrMeta: IDateMeta | Date): IHoliday | undefined {
    return find(this.currentYearHolidays, (o) => this.cmp(dateOrMeta)(o.observedDate));
  }

  getHolidayByName(name: string): IHoliday | undefined {
    return find(this.currentYearHolidays, {name});
  }

  isMovedHoliday(dateOrMeta: IDateMeta | Date, byObserved: boolean): IHoliday | undefined {
    const holiday = find(this.currentYearHolidays,
      (o) => this.cmp(dateOrMeta)(byObserved ? o.observedDate : o.date));
    return (holiday != null && !this.cmp(holiday.date)(holiday.observedDate)) ? holiday : undefined;
  }

  getTooltip(dateOrMeta: IDateMeta | Date): string | undefined {
    let holiday = this.getHolidayByDate(dateOrMeta);
    if (holiday) {
      return holiday.name;
    }
    holiday = this.isMovedHoliday(dateOrMeta, false);
    if (holiday) {
      return `${holiday.name} - moved to ${(new DatePipe('en-US')).transform(holiday.observedDate, 'MM/dd/yy')}`;
    }
    return undefined;
  }

  getSelected(dateOrMeta: IDateMeta | Date): Date | undefined {
    return find(this.selectedDates || [], this.cmp(dateOrMeta));
  }

  cmp(dateOrMeta1: IDateMeta | Date): (dateOrMeta2: IDateMeta | Date) => boolean {
    const dateMeta1: IDateMeta = isDate(dateOrMeta1) ? this.dateToMeta(dateOrMeta1 as Date) : dateOrMeta1;
    return (dateOrMeta2: IDateMeta | Date) => {
      const dateMeta2: IDateMeta = isDate(dateOrMeta2) ? this.dateToMeta(dateOrMeta2 as Date) : dateOrMeta2;
      return this.metaToDate(dateMeta2).getTime() === this.metaToDate(dateMeta1).getTime();
    };
  }

  dateToMeta(date: Date): IDateMeta {
    return {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear()
    }
  }

  metaToDate(dateMeta: IDateMeta): Date {
    return new Date(dateMeta.year, dateMeta.month, dateMeta.day);
  }

  incrementDay(date: Date, inc = 1): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + inc);
  }

  onHolidayListChange(): void {
    this.applyValue();
  }

  toggleDay(dateOrMeta: IDateMeta | Date): void {
    if (this.getSelected(dateOrMeta)) {
      remove(this.selectedDates, this.cmp(dateOrMeta));
    } else {
      const dateMeta: IDateMeta = isDate(dateOrMeta) ? this.dateToMeta(dateOrMeta as Date) : dateOrMeta;
      this.selectedDates.push(this.metaToDate(dateMeta));
    }
  }

  onClickDay(dateMeta: IDateMeta, event: MouseEvent, editorPanel: OverlayPanel, dayEl: any): void {
    event.preventDefault();
    event.stopPropagation();
    if (editorPanel.overlayVisible) {
      editorPanel.hide();
      return;
    }

    const select = () => {
      this.toggleDay(dateMeta);
      this.selectedDates = sortBy(this.selectedDates);
      this.applyValue();
    };

    const existedHoliday = this.getHolidayByDate(dateMeta);
    if (!existedHoliday) {
      this.createHolidayData = {
        day: this.metaToDate(dateMeta),
        formData: {},
        onApply: ((name) => {
          this.currentYearHolidays.push({
            name,
            date: this.metaToDate(dateMeta),
            observedDate: this.metaToDate(dateMeta),
            isCustom: true,
          });
          this.currentYearHolidays.sort((h1, h2) => h1.observedDate < h2.observedDate ? -1 : 1);
          select();
        })
      };
      editorPanel.show(event);
    } else {
      select();
    }
  }

  removeDay(event: MouseEvent, holiday: IHoliday): boolean {
    event.preventDefault();
    event.stopPropagation();
    remove(this.currentYearHolidays, {name: holiday.name});
    remove(this.selectedDates, this.cmp(holiday.observedDate));
    this.applyValue();
    return false;
  }

  onChangePeriod(): void {
    this.currentYearHolidays.forEach((h) => {
      h.isInactive = !this.getSelected(h.observedDate);
    });
    this.currentYear = this.selectedPeriod.start.getFullYear();

    this.currentYearHolidays = find(this.data!.holidayRange, {year: this.currentYear})!.holidays;
    this.selectedDates = this.currentYearHolidays.filter((h) => !h.isInactive).map((h) => h.observedDate);
  }


  applyValue(): void {
    this.holidaysChanged = true;
    this.currentYearHolidays.forEach((h) => {
      h.isInactive = !this.getSelected(h.observedDate);
    });
    this.value = this.data;
    setTimeout(() => {
      this.onModelChange(this.value);
      this.onModelTouched();
    });
  }

  override writeValue(value: any) {
    if (!!value) {
      this.holidaysChanged = false;
      this.setData(value, false);
      if (value?.baseId) {
        this.basicCalendarId = value.baseId;
      }
      super.writeValue(this.data);
    } else {
      this.data = null;
    }
  }

  private setData(calendar: IHolidayCalendar, fromBasic: boolean): void {
    let data = {...calendar};
    const isNew = isEmpty(data);
    if (isNew || fromBasic) {
      data.isArchived = false;
      data.countryCode = 'US'; // TODO: take from user profile
    } else if (this.isDuplicate) {
      data.baseId = data.baseId ?? data.id as string;
      data.isArchived = false;
    }

    this.selectedPeriod = getCurrentPeriodValue('year');
    this.currentYear = this.selectedPeriod.start.getFullYear();

    if (!data.holidayRange || data.holidayRange.length < DEFAULT_CALENDAR_YEAR_RANGE.backward + DEFAULT_CALENDAR_YEAR_RANGE.forward + 1) {

      const defaultYearRange = this.getDefaultYearRange();
      const dataYearRange = {
        start: minBy(data.holidayRange ?? [], 'year')?.year || defaultYearRange.start,
        end: maxBy(data.holidayRange ?? [], 'year')?.year || defaultYearRange.end
      };
      dataYearRange.start = Math.min(dataYearRange.start, defaultYearRange.start);
      dataYearRange.end = Math.max(dataYearRange.end, defaultYearRange.end);
      data.holidayRange = range(dataYearRange.start, dataYearRange.end + 1).map((year) => (
        find(calendar.holidayRange || [], {year}) ?? {
          year,
          holidays: []
        }));
    }

    data.holidayRange.forEach((yh) => yh.holidays.sort((h1, h2) => h1.observedDate < h2.observedDate ? -1 : 1));
    this.yearRange = {
      start: minBy(data.holidayRange, 'year')!.year,
      end: maxBy(data.holidayRange, 'year')!.year
    };
    this.updateDateRange();
    if (fromBasic) {
      if (!this.isStandalone) {
        data = omit(data, ['name', 'description', 'isArchived']) as IHolidayCalendar;
      }
      data.baseId = calendar.baseId ?? calendar.id;
    }
    this.data = data; //omit(data, HOLIDAY_CALENDAR_EXCLUDED_PROPS);
    this.onChangePeriod();
    if (this.nameCtrl) {
      this.nameCtrl.markAsDirty();
      this.nameCtrl.updateValueAndValidity();
    }
  }

  override validate(control: AbstractControl<any, any>): ValidationErrors | null {
    if (!this.nameCtrl) {
      return null;
    }
    const invalid = this.nameCtrl.invalid || !this.data?.description;
    if (invalid) {
      return {
        invalidData: true
      }
    }
    return null;
  }

  setArchived(): void {
    this.data!.isArchived = true;
  }
}
