import {
  AfterContentInit,
  Component,
  ContentChildren,
  Directive,
  Input,
  OnInit,
  QueryList,
  TemplateRef
} from '@angular/core';
import {AbstractEntityEditorBase} from './abstract-entity-editor-base';
import {IIdentified} from '../../../api/shared/common';
import {finalize} from 'rxjs';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn} from '@angular/forms';
import {find, indexOf, intersection, isArray, keys, pullAll, startCase, trimEnd, uniq, values} from 'lodash';
import {plural} from '../../util/util';
import {PrimeTemplate, SelectItem} from 'primeng/api';
import {IEntityResource} from '../../services/resources/entity-resource';
import {Alert} from '../../util/alert';
import {RadioButtonModule} from 'primeng/radiobutton';
import {NgClass, NgFor, NgIf, NgTemplateOutlet} from '@angular/common';
import {DividerModule} from 'primeng/divider';
import {MultiSelectModule} from 'primeng/multiselect';
import {SpinnerizerComponent} from '../spinnerizer.component';

import {stdConfirm} from '../confirm';

export interface IEntityBulkEditorParam {
  ids: Array<string>;
}

export type TBulkFieldDescriptor<S> = {
  [field in keyof Partial<S>]: {
    label?: string;
    isArray?: boolean;
    deps?: Array<keyof S>;
    validators?: ValidatorFn[];
    defaultValue?: any;
  }
};

@Directive()
export abstract class AbstractEntityBulkEditor<S extends {}, T extends S & IIdentified> extends AbstractEntityEditorBase<T> implements OnInit {
  @Input() param!: IEntityBulkEditorParam;
  fieldDescriptors: TBulkFieldDescriptor<S> = {} as any;
  api: Partial<IEntityResource<S, T>> = {};

  entityName?: string;
  formControls: { [name in keyof Partial<S>]: FormControl } = {} as any;
  arrayUpdateMethods: { [field in keyof Partial<S>]?: boolean } = {};
  formFields: Array<keyof S> = [];

  ngOnInit(): void {
    this._title = `Bulk edit ${this.param.ids.length} ${(this.param.ids.length > 1 ? plural(this.entityName) : this.entityName) || 'record(s)'}`;
    this.form = new FormGroup({});

    let field: keyof S;
    for (field in this.fieldDescriptors) {
      const fieldDescriptor = this.fieldDescriptors[field]!;
      fieldDescriptor.label = fieldDescriptor.label || startCase(trimEnd(field, 'Id'));
      this.formControls[field] = new FormControl(fieldDescriptor.defaultValue ?? null, fieldDescriptor.validators);
    }
  }

  onChangeSelectedFields(selectedFields: Array<keyof S>): void {
    this.formFields = [];
    this._applyAttempted = false;
    this.form = new FormGroup({});
    let isDirty = false;
    const formFields: Array<keyof S> = [];
    for (const field of selectedFields) {
      const formControl = this.formControls[field]!;
      this.form.addControl<any>(field, formControl);
      isDirty = isDirty || formControl.dirty;
      formFields.push(field);
    }
    this.formFields = formFields;
    this.touchAllFormFields(this.form, true);
    if (isDirty) {
      this.form.markAsDirty();
    }
  }

  override canSubmit(): boolean {
    if (!this.formFields?.length) {
      return false;
    }
    return super.canSubmit();
  }

  override canPerformApply(): boolean {
    if (!this.formFields?.length) {
      return false;
    }
    return super.canPerformApply();
  }

  override async apply(onApply: (result: any) => void) {
    if (this.api.patchEntities) {
      const data: S = this.form!.getRawValue() as S;
      let field: keyof S;
      for (field in data) {
        if (isArray(data[field]) && !this.arrayUpdateMethods[field]) {
          (data[field] as any).unshift(null);
        }
      }
      const entities = this.param.ids.map((id) => ({
        ...{id},
        ...data
      }));

      if (await stdConfirm({
        header: 'Bulk Update Confirmation',
        message: `Are you sure you want to bulk update ${this.param.ids.length} record(s)?`,
      })) {
        this._loading = true;
        this.api.patchEntities!(entities)
          .pipe(
            finalize(() => this._loading = false)
          )
          .subscribe((result) => {
            Alert.message({
              severity: 'success',
              summary: 'Success',
              detail: `${this.param.ids.length} records(s) have been updated`
            });
            onApply(result);
          });
      }
    }
  }
}

@Component({
  selector: 'app-entity-bulk-editor-form',
  template: `
    <app-spinnerizer [active]="bulkEditor.loading"></app-spinnerizer>
    <div class="ml-2 mr-2">
      <div class="flex align-items-center p-fluid">
        <div class="mr-2 font-medium text-lg">Select fields to update:</div>
        <div class="flex-1">
          <p-multiSelect class="mt-show-all-chips" [(ngModel)]="selectedFields" [options]="fieldOptions"
                         panelStyleClass="fields-panel"
                         display="chip" [showClear]="true" appendTo="body"
                         (ngModelChange)="onChangeSelectedFields()"></p-multiSelect>
        </div>
      </div>
      <p-divider></p-divider>
    </div>
    <form *ngIf="bulkEditor.form" [formGroup]="bulkEditor.form">
      <div *ngFor="let field of bulkEditor.formFields" class="field col-12 p-fluid">
        <div [ngClass]="getDependentClass($any(field))">
          <ng-content *ngTemplateOutlet="tplEntityFormControl; context:{$implicit: field}"></ng-content>
          <ng-container *ngIf="bulkEditor.fieldDescriptors[$any(field)]!.isArray">
            <div class="mt-3 flex align-items-center text-sm">
              <p-radioButton [(ngModel)]="bulkEditor.arrayUpdateMethods[$any(field)]" [value]=""
                             [ngModelOptions]="{standalone: true}"></p-radioButton>
              <label class="ml-2">Append to current {{ bulkEditor.fieldDescriptors[$any(field)]!.label }}</label>
              <p-radioButton class="ml-4" [(ngModel)]="bulkEditor.arrayUpdateMethods[$any(field)]" [value]="true"
                             [ngModelOptions]="{standalone: true}"></p-radioButton>
              <label class="ml-2">Replace current {{ bulkEditor.fieldDescriptors[$any(field)]!.label }}</label>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  `,
  styles: [
    `

      ::ng-deep .fields-panel .p-multiselect-item.p-disabled {
        opacity: 1;
      }

      ::ng-deep .fields-panel .p-multiselect-item.p-disabled .p-checkbox {
        visibility: hidden;
        margin-right: 2rem;
      }

      .dependent {
        position: relative;
        margin-left: 30px;
      }

      .dependent:after,
      .dependent:before {
        content: '';
        border-color: #CACACA;
        border-style: solid;
        position: absolute;
        left: -15px;
        width: 15px;
        border-width: 0;
      }

      .dependent:before {
        height: 0;
        top: 36px;
        border-width: 0 0 1px 0;
      }

      .dependent:after {
        bottom: calc(100% - 36px);
        height: calc(100% + 6px);
        border-width: 0 0 0 1px;
      }

      .dependent:not(.last-dep):after {
        bottom: 0;
        height: calc(100% + 24px);
        border-width: 0 0 0 1px;
      }

      ::ng-deep .dependent-node .p-checkbox-box {
        display: none;
      }
    `
  ],
  standalone: true,
  imports: [
    SpinnerizerComponent, MultiSelectModule, FormsModule, DividerModule, NgIf, ReactiveFormsModule, NgFor, NgClass,
    NgTemplateOutlet, RadioButtonModule
  ]
})
export class EntityBulkEditorFormComponent implements AfterContentInit {
  bulkEditor!: AbstractEntityBulkEditor<any, any>;
  protected tplEntityFormControl!: TemplateRef<any>;
  @ContentChildren(PrimeTemplate) protected templates!: QueryList<PrimeTemplate>;
  fieldOptions: Array<SelectItem> = [];
  selectedFields: Array<string> = [];


  constructor(editor: AbstractEntityEditorBase<any>) {
    if (editor instanceof AbstractEntityBulkEditor) {
      this.bulkEditor = editor;
    } else {
      throw new Error('app-entity-bulk-editor-form is used with EntityBulkEditorComponent instances only');
    }
  }

  ngAfterContentInit(): void {
    const deps = this.getAllDependents();

    keys(this.bulkEditor.fieldDescriptors).forEach((f) => {
      const descr = this.bulkEditor.fieldDescriptors;

      if (!deps.includes(f)) {
        this.fieldOptions.push({
          label: descr[f].label,
          value: f
        });
        for (const dep of descr[f].deps || []) {
          this.fieldOptions.push({
            label: descr[dep as string].label,
            value: dep,
            disabled: true
          });
        }
      }
    });
    this.templates.forEach((tpl) => {
      if (!tpl.getType() || tpl.getType() === 'entityFormControl') {
        this.tplEntityFormControl = tpl.template;
      }
    });
  }

  getAllDependents(): Array<string> {
    let deps: Array<any> = [];
    values(this.bulkEditor.fieldDescriptors).forEach((v) => {
      deps = [...deps, ...(v.deps || [])];
    });
    return deps;
  }

  getDependentClass(field: string): string {
    const descr = find(values(this.bulkEditor.fieldDescriptors), (v) => (v.deps || []).includes(field));
    if (!descr) {
      return '';
    }
    const i = indexOf(descr.deps, field);
    let result = ['dependent'];
    if (i === 0) {
      result.push('first-dep');
    } else if (i === descr.deps!.length - 1) {
      result.push('last-dep');
    }
    return result.join(' ');
  }

  getParent(field: string): any {
    const descr = this.bulkEditor.fieldDescriptors;
    const parentField = find(keys(descr), (f) => (descr[f].deps || []).includes(field));
    return parentField ? descr[parentField] : undefined;
  }

  onChangeSelectedFields(): void {
    const descr = this.bulkEditor.fieldDescriptors;
    let selectedFields = [...(this.selectedFields || [])];
    [...selectedFields].forEach((sf) => {
      const parent = this.getParent(sf);
      if (descr[sf].deps || parent?.deps) {
        const deps = [...[sf], ...(descr[sf].deps || parent?.deps)];
        const inter = intersection(selectedFields, deps);
        if (inter.length !== deps.length && !(inter.length === 1 && inter[0] === sf)) {
          pullAll(selectedFields, deps);
        }
      }
    });
    selectedFields = uniq(selectedFields);
    const sel = [...selectedFields];
    selectedFields = [];
    sel.forEach((sf, i) => {
      selectedFields.push(sf);
      for (const dep of descr[sf].deps || []) {
        selectedFields.splice(i + 1, 0, dep as string);
      }
    });
    // preserve order
    this.selectedFields = intersection(keys(descr), uniq(selectedFields));
    this.bulkEditor.onChangeSelectedFields(this.selectedFields);
  }
}
