import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'arrayOf',
    standalone: true
})
export class ArrayOfPipe implements PipeTransform {
  transform(value: any): any {
    return Array(value)
      .fill(0)
      .map((v, i) => i);
  }
}
