import {Component, ViewChild} from '@angular/core';
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel';
import {MenuModule} from 'primeng/menu';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {MenuItem, SharedModule} from 'primeng/api';
import {SETTINGS_MENU} from '../main-menu-models';

@Component({
  selector: 'app-settings-nav',
  template: `
    <button pButton pRipple type="button" icon="pi pi-cog"
            class="p-button-rounded p-button-text text-white"
            (click)="opSettings.toggle($event)">
    </button>
    <p-overlayPanel #opSettings styleClass="mt-user-popup">
      <ng-template pTemplate>
        <p-menu [model]="settingsMenuItems"></p-menu>
      </ng-template>
    </p-overlayPanel>
  `,
  standalone: true,
  imports: [
    OverlayPanelModule,
    MenuModule,
    RippleModule,
    ButtonModule,
    SharedModule
  ]
})
export class SettingsNav {
  settingsMenuItems: MenuItem[];
  @ViewChild('opSettings') settingsMenuPanel!: OverlayPanel;

  constructor() {
    this.settingsMenuItems = SETTINGS_MENU.map((item) => {
      return {
        ...item,
        ...{
          command: () => this.settingsMenuPanel.hide()
        }
      };
    });
  }
}
