import {Component} from '@angular/core';
import {MenuItem, SharedModule} from 'primeng/api';
import {AuthService} from '../../../shared/services/auth/auth.service';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {MAIN_MENU} from './main-menu-models';
import {RouterOutlet} from '@angular/router';
import {AppConfigService} from '../../services/app-config.service';
import {BadgeModule} from 'primeng/badge';
import {MenuModule} from 'primeng/menu';
import {SkeletonModule} from 'primeng/skeleton';
import {MenubarModule} from 'primeng/menubar';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ToastModule} from 'primeng/toast';
import {TenantService} from '../../../shared/services/tenant/tenant.service';
import {PanelMenuModule} from 'primeng/panelmenu';
import {ListboxModule} from 'primeng/listbox';
import {UserNavComponent} from './nav/user-nav.component';
import {SettingsNav} from './nav/settings-nav.component';
import {MainMenuNavComponent} from './nav/main-menu-nav.component';


@Component({
  selector: 'app-main-page',
  template: `
    <div style="position: fixed; top: 0; right: 10px; color: red; z-index: 100">
      Tenant: {{ tenant.tenantId || 'NONE' }} Signed: {{ auth.isSignedIn.toString().toUpperCase() }}
    </div>
    <div class="mt-app-main" [class.mt-show-alert-bar]="auth.isSignedIn && showAlertBar">
      @if (auth.isSignedIn) {
        @if (showAlertBar) {
          <div class="mt-alert-bar">
            <div class="grid grid-nogutter w-full">
              <div class="col-6 flex align-items-center">
                <span>10 trial days remaining.</span>
                <a class="ml-2 opacity-80 cursor-pointer no-underline text-white"
                   [href]="'/tbd'">View pricing plans.</a>
              </div>
              <div class="col-6 flex justify-content-end align-items-center">
                <a class="ml-2 opacity-80 cursor-pointer no-underline text-white"
                   [href]="'/tbd'">Sign up for a free training webinar</a>
                <button pButton pRipple type="button" icon="pi pi-times"
                        class="ml-3 p-button-rounded p-button-text"
                        (click)="showAlertBar=false"></button>
              </div>
            </div>
          </div>
        }
        <div class="mt-app-top-bar justify-content-between">
          <app-main-menu-nav></app-main-menu-nav>
          <div class="flex align-items-center justify-content-end h-full">
            <ng-container>
              <button pButton pRipple type="button" label="Track Time"
                      icon="pi pi-clock"
                      class="p-button-rounded p-button-text text-white text-sm mr-4"
                      (click)="opTrackTime.toggle($event)">
              </button>
              <p-overlayPanel #opTrackTime>
                <ng-template pTemplate>
                  <h5>Time Entry</h5>
                  <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
                  <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
                  <p-skeleton width="15rem" height="4rem" styleClass="mb-2"></p-skeleton>
                </ng-template>
              </p-overlayPanel>
            </ng-container>
            <app-settings-nav></app-settings-nav>
            <button pButton pRipple type="button" icon="pi pi-bell"
                    class="p-button-rounded p-button-text text-white relative">
              <p-badge [value]="'5'" severity="success" class="absolute" [style.top]="'.2rem'" [style.right]="'.4rem'">
              </p-badge>
            </button>
            <div class="ml-3 mr-3 mt-app-top-bar-divider"></div>
            <app-user-nav></app-user-nav>
          </div>
        </div>
      }
      <div class="mt-app-desktop">
        <router-outlet></router-outlet>
      </div>
      @if (false) {
        <div class="mt-app-bottom-bar">
          <img alt="Mindtrust" src="assets/images/mindtrust.svg" height="26"/>
          <a class="bottom-bar-link" [href]="'/tbd'">Terms of Service</a>
          <a class="bottom-bar-link" [href]="'/tbd'">Privacy Policy</a>
        </div>
      }
    </div>

  `,
  styles: [
    `
      :host ::ng-deep .p-toast {
        width: 50rem;
      }

      :host ::ng-deep .p-toast .p-toast-message .p-toast-icon-close {
        min-width: 2rem;
        min-height: 2rem;
      }

      .bottom-bar-link {
        color: #7F8B96;
        font-weight: 500;
        font-size: 12px;
        margin-left: 3rem;
        text-decoration: none;
      }
    `
  ],
  standalone: true,
  imports: [
    ToastModule, ConfirmPopupModule, ConfirmDialogModule, NgIf, ButtonModule, RippleModule, MenubarModule,
    OverlayPanelModule, SharedModule, SkeletonModule, MenuModule, BadgeModule, RouterOutlet, PanelMenuModule,
    NgOptimizedImage,
    ListboxModule, UserNavComponent, SettingsNav, MainMenuNavComponent
  ]
})
export class MainPageComponent {
  showAlertBar = true;

  constructor(public auth: AuthService,
              public tenant: TenantService) {
  }
}
