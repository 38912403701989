import {Component} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {MAIN_MENU} from '../main-menu-models';
import {AppConfigService} from '../../../services/app-config.service';
import {MenubarModule} from 'primeng/menubar';

@Component({
  selector: 'app-main-menu-nav',
  template: `
    <div class="flex align-items-center">
      <img alt="logo" class="mr-2" height="30" [src]="appConfig.userProfile?.tenant?.avatar"/>
      <p-menubar [model]="mainMenuItems" [autoDisplay]="false"></p-menubar>
    </div>

  `,
  standalone: true,
  imports: [
    MenubarModule
  ]
})
export class MainMenuNavComponent {
  mainMenuItems: MenuItem[];
  constructor(public appConfig: AppConfigService) {
    this.mainMenuItems = MAIN_MENU.map((item) => {
      return {
        ...item
      };
    });
  }
}
