import {Component, OnChanges, ViewChild} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {HolidayCalendarControl} from './holiday-calendar.control';
import {inheritanceProvider} from '../../../../../shared/util/util';
import {AbstractEntityEditorBase} from '../../../../../shared/components/entity-editor/abstract-entity-editor-base';
import {AbstractEntityEditor} from '../../../../../shared/components/entity-editor/abstract-entity-editor';
import {IHolidayCalendar, THolidayCalendarSave} from '../../../../../api/shared/app-domain/holidays';
import {HolidayCalendarsResourceService} from '../../../../resources/dictionaries/holiday-calendars-resource.service';
import {SpinnerizerDirective} from '../../../../../shared/components/spinnerizer.component';
import {MessagesModule} from 'primeng/messages';
import {NgIf} from '@angular/common';
import {ControlErrorComponent} from '../../../../../shared/components/control-error.component';
import {TControlsOf} from '../../../../../shared/types';
import {omit} from 'lodash';
import {HOLIDAY_CALENDAR_EXCLUDED_PROPS} from '../../../../resources/types';

interface ICalendar {
  calendar: THolidayCalendarSave;
}

@Component({
  selector: 'app-holiday-calendar-editor',
  template: `
    <div [spinnerizer]="loading">
      <form *ngIf="form" [formGroup]="form">
        <app-holiday-calendar formControlName="calendar" [checkExists]="checkExists"
                              [isStandalone]="true" [isDuplicate]="param && !!param.duplicate"
                              [showBasicCalendarChooser]="isNew()"></app-holiday-calendar>
      </form>
    </div>
  `,
  providers: [
    inheritanceProvider(AbstractEntityEditorBase, HolidayCalendarEditorComponent)
  ],
  standalone: true,
  imports: [
    NgIf, MessagesModule, HolidayCalendarControl, FormsModule, ControlErrorComponent,
    ReactiveFormsModule, SpinnerizerDirective
  ]
})
export class HolidayCalendarEditorComponent extends AbstractEntityEditor<THolidayCalendarSave, IHolidayCalendar> implements OnChanges {
  @ViewChild(HolidayCalendarControl) calendar!: HolidayCalendarControl;
  checkExists!: (value: any) => Observable<boolean>;

  constructor(public holidayCalendarsResource: HolidayCalendarsResourceService) {
    super(holidayCalendarsResource, 'Holiday Calendar');
    this.checkExists = this.getCheckExists(this.holidayCalendarsResource.searchEntities.bind(this.holidayCalendarsResource) as any, 'name');
  }

  protected override getData(): THolidayCalendarSave {
    return omit((this.form!.getRawValue() as any).calendar, HOLIDAY_CALENDAR_EXCLUDED_PROPS) as THolidayCalendarSave;
  }

  override buildForm(): void {
    this.form = this.fb.group<TControlsOf<any>>({
      calendar: new FormControl(this.entity),
    }) as any;
  }

  override assignValue(field: keyof TControlsOf<THolidayCalendarSave>, value: any) {
    if (field === 'isArchived' && value === true) {
      this.calendar.setArchived();
    }
  }
}
