import {Directive, ViewChild} from '@angular/core';
import {IDataModifier} from '../common';
import {EntityEditPageComponent} from './entity-edit-page.component';

@Directive()
export class AbstractEditPageWrapper implements IDataModifier {
  @ViewChild(EntityEditPageComponent) editPage!: EntityEditPageComponent;

  hasUnsavedChanges() {
    return this.editPage.isDataChanged();
  };
}
