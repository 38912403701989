import {Injectable} from '@angular/core';
import {Endpoint, EntityResourceBaseService} from '../../../shared/services/resources/entity-resource-base.service';
import {IRole, TRoleSave} from '../../../api/shared/app-domain/dictionaries';
import {Observable} from 'rxjs';
import {OnDemandLoader} from '../../../shared/services/resources/on-demand-resource-loader.service';

@Injectable({
  providedIn: 'root',
})
@Endpoint('/api/settings/roles')
export class RolesResourceService extends EntityResourceBaseService<TRoleSave, IRole> {

  @OnDemandLoader('roles')
  roles(): Observable<Array<IRole>> {
    return this.distinctFetchHelper({sort: [{field: 'name'}], fields: ['id', 'name']});
  }

}
