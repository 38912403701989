import {Inject, Injectable} from '@angular/core';
import {BypassHttpService} from '../http/bypass-http.service';
import {decodeJwtPayload, unixTime} from '../../util/util';
import {AuthService} from './auth.service';
import {IAuthResponse, IRefreshTokenPayload} from '../../../api/shared/auth-user-api';
import {firstValueFrom} from 'rxjs';
import {COMMON_LIB_CONFIG_TOKEN, ICommonLibConfig} from '../../common-lib-config';
import {IJwtToken} from './auth-user';
import {TokenStorageService} from './token-storage.service';


const REFRESH_SAFETY_SECONDS = 5;

@Injectable({
  providedIn: 'root'
})
export class TokenSupplierService {

  private promise: Promise<string | null> | null = null;

  constructor(private bypassHttp: BypassHttpService,
              private authService: AuthService,
              private tokenStorage: TokenStorageService,
              @Inject(COMMON_LIB_CONFIG_TOKEN) private commonConfig: ICommonLibConfig) {
  }

  getOrRefreshToken(): Promise<string | null> {
    const accessToken = this.tokenStorage.getAccessToken();
    if (!this.isExpired(accessToken)) {
      return Promise.resolve(accessToken);
    }
    if (!this.promise) {
      this.promise = this.fetchToken();
    }
    return this.promise;
  }

  isExpired(token: string | null) {
    if (!token) {
      return true;
    } else {
      const decoded = decodeJwtPayload<IJwtToken>(token);
      return decoded.exp != null && (decoded.exp - REFRESH_SAFETY_SECONDS < unixTime());
    }
  }

  async fetchToken(): Promise<string | null> {
    try {
      const refreshToken = this.tokenStorage.getRefreshToken();
      if (refreshToken && !this.isExpired(refreshToken)) {
        const response = await firstValueFrom(
          this.bypassHttp.request<IAuthResponse, IRefreshTokenPayload>('post', this.commonConfig.auth.refreshTokenUrl, {
            body: {
              refreshToken
            }
          }));
        const token = response.token;
        this.authService.setUser(token);
        return token;
      } else {
        return null;
      }
    } catch (e) {
      throw e;
    } finally {
      setTimeout(() => this.promise = null);
    }
  }

}
