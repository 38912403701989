import {Component} from '@angular/core';
import {MainPageComponent} from './taasinator/components/main/main-page.component';
import {AbstractRootComponent} from './shared/components/abstract-root-component';

@Component({
  selector: 'app-root',
  template: `
    <app-main-page></app-main-page>`,
  standalone: true,
  imports: [MainPageComponent]
})
export class AppComponent extends AbstractRootComponent {
}
