import {Component} from '@angular/core';
import {IDepartment, TDepartmentSave} from '../../../../api/shared/app-domain/dictionaries';
import {inheritanceProvider} from '../../../../shared/util/util';
import {AbstractEntityEditorBase} from '../../../../shared/components/entity-editor/abstract-entity-editor-base';
import {AbstractEntityEditor} from '../../../../shared/components/entity-editor/abstract-entity-editor';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TControlsOf} from '../../../../shared/types';
import {DepartmentsResourceService} from '../../../resources/dictionaries/departments-resource.service';
import {AbstractFieldFormControl} from '../../../../shared/components/entity-editor/form-control-wrapper.component';
import {existsAsyncValidator} from '../../../../shared/util/validators';
import {NgClass, NgIf} from '@angular/common';
import {SpinnerizerDirective} from '../../../../shared/components/spinnerizer.component';
import {
  CommonColumnHeaderIsArchived,
  CommonFormControlIsArchived,
  CommonFormControlName,
  CommonTableCellIsArchived,
  CommonTableCellName
} from '../../common/common-controls-wrappers';
import {ColumnHeaderComponent} from '../../../../shared/components/table/column-header.component';
import {EntityTableComponent} from '../../../../shared/components/table/entity-table.component';
import {SharedModule} from 'primeng/api';
import {EntityEditDialogComponent} from '../../../../shared/components/entity-editor/entity-edit.dialog';
import {CommonPageComponent} from '../../../../shared/components/page/common-page.component';
import {ButtonModule} from 'primeng/button';

@Component({
  selector: 'app-department-form-control',
  template: `
    @switch (field) {
      @case ('name') {
        <app-fc-name/>
      }
      @case ('isArchived') {
        <app-fc-isArchived/>
      }
    }
  `,
  standalone: true,
  imports: [CommonFormControlName, CommonFormControlIsArchived]
})
export class DepartmentFormControlComponent extends AbstractFieldFormControl<TDepartmentSave> {
}

@Component({
  selector: 'app-department-editor',
  template: `
    <div [spinnerizer]="loading">
      <form *ngIf="form" [formGroup]="form">
        <div class="formgrid grid">
          <div class="field p-fluid" [ngClass]="isNew() ? 'col-12' : 'col-8'">
            <app-department-form-control field="name"></app-department-form-control>
          </div>
          <div *ngIf="!isNew()" class="field col-4 p-fluid">
            <app-department-form-control field="isArchived"></app-department-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    inheritanceProvider(AbstractEntityEditorBase, DepartmentEditorComponent)
  ],
  standalone: true,
  imports: [
    NgIf, FormsModule, ReactiveFormsModule, NgClass, DepartmentFormControlComponent,
    SpinnerizerDirective
  ]
})
export class DepartmentEditorComponent extends AbstractEntityEditor<TDepartmentSave, IDepartment> {

  constructor(public departmentsResource: DepartmentsResourceService) {
    super(departmentsResource, 'Department');
  }

  override buildForm(): void {
    const checkExistsName = this.getCheckExists(this.departmentsResource.searchEntities.bind(this.departmentsResource), 'name');
    this.form = this.fb.group<TControlsOf<TDepartmentSave>>({
      name: new FormControl(this.entity!.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExistsName)]
      }),
      isArchived: new FormControl(this.isNew() ? false : this.entity!.isArchived, [Validators.required]),
    });
  }
}

@Component({
  selector: 'app-departments-table',
  template: `
    <app-entity-edit-dialog #editDialog width="500px">
      <ng-template pTemplate let-param>
        <app-department-editor [param]="param"></app-department-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-table #entityTable
                      [api]="departmentsResource" [defaultSort]="[{field: 'name'}]"
                      [pageable]="false" [omitMenuItems]="['edit']"
                      [editDialog]="editDialog" stateKey="departments.table">
      <ng-template pTemplate="$header">
        <app-th field="name" filterType="text"></app-th>
        <app-th-isArchived/>
      </ng-template>
      <ng-template pTemplate="$body" let-rowData>
        <app-td-name [rowData]="rowData" [omitMenuItems]="['duplicate']"
                     (onClick)="entityTable.openCreateOrUpdate(rowData)"/>
        <app-td-isArchived [rowData]="rowData"/>
      </ng-template>
    </app-entity-table>
  `,
  standalone: true,
  imports: [
    EntityEditDialogComponent, SharedModule, DepartmentEditorComponent, EntityTableComponent, ColumnHeaderComponent,
    CommonColumnHeaderIsArchived, CommonTableCellName, CommonTableCellIsArchived
  ]
})
export class DepartmentsTableComponent {
  constructor(public departmentsResource: DepartmentsResourceService) {
  }
}

@Component({
  selector: 'app-departments-page',
  template: `
    <app-common-page entityName="Department" onDemandCountMethod="departmentsCount" stateKey="departments">
      <ng-template pTemplate="content">
        <app-departments-table></app-departments-table>
      </ng-template>
    </app-common-page>
  `,
  standalone: true,
  imports: [CommonPageComponent, DepartmentsTableComponent, SharedModule],
})
export class DepartmentsPageComponent {
}
