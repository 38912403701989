import {Injectable} from '@angular/core';
import {Endpoint, EntityResourceBaseService} from '../../../shared/services/resources/entity-resource-base.service';
import {IExpenseCategory, TExpenseCategorySave} from '../../../api/shared/app-domain/dictionaries';

@Injectable({
  providedIn: 'root',
})
@Endpoint('/api/settings/expense-categories')
export class ExpenseCategoriesResourceService extends EntityResourceBaseService<TExpenseCategorySave, IExpenseCategory> {
}
